import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import { GET_PAGINATED_FILECASES } from 'src/graphql/queries/filecases';

import { useDialog } from 'src/dialogs/Dialogs';
import { Stack } from '@mui/material';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import Header, { useFilecasesFilters } from './Header';
import { useColumns } from './columns';
import { useFilecasesToExport } from './excel';

const FilecasesTable = () => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const [selectedItems, setSelectedItems] = useState([]);
  const columns = useColumns();

  const [pagination, setPagination] = useState({
    page: 0,
    limit: defaultPageSize,
  });
  const studioId = useStudioId();
  const { filters } = useFilecasesFilters();

  const queryVariables = {
    studio: studioId,
    limit: pagination.limit || defaultPageSize,
    offset: pagination.page * pagination.limit,
    orderBy: pagination.orderBy,
    sortOrder: pagination.sortOrder,
    tags: filters.tags?.map((tag) => tag?.value ?? tag),
    filecases: filters.filecases?.map(
      (filecase) => filecase?.value ?? filecase,
    ),
    assigned: filters.assigned?.map((assigned) => assigned?.value ?? assigned),
    entities: filters.entities?.map((entity) => entity?.value ?? entity),
    search: filters.search,
    onlyFavorites: filters.onlyFavorites,
    archived: filters.archived,
    category: filters.category,
    state: filters.state,
  };

  const {
    data: { paginatedFilecases } = { paginatedFilecases: {} },
    loading,
    variables,
    previousData,
    refetch,
  } = useQuery(GET_PAGINATED_FILECASES, {
    onCompleted: () => setSelectedItems([]),
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    variables: queryVariables,
    skip: !studioId,
  });

  const handlePaginationModelChange = ({ pageSize, page }) =>
    refetch({
      ...variables,
      limit: pageSize,
      offset: pageSize * page,
    }).then(() => {
      setPagination({ page, limit: pageSize });
    });

  const { getFilecasesToExport, loading: loadingFilecasesToExport } =
    useFilecasesToExport();

  const handleDownloadAsExcel = async () => {
    const params = { ...variables, ids: selectedItems };

    if (!selectedItems?.length) {
      params.limit = paginatedFilecases?.data?.count;
    }

    await getFilecasesToExport(params);
  };

  const rowCount =
    paginatedFilecases?.count ?? previousData?.paginatedFilecases?.count ?? 0;

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        loadingFilecasesToExport={loadingFilecasesToExport}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        subtitle={`Totales ${rowCount}`}
        title="Expedientes"
        totalItems={rowCount}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'filecases',
          },
          noRowsOverlay: {
            variant: 'filecases',
          },
        }}
        loading={loading || loadingFilecasesToExport}
        paginationModel={{ page: pagination.page, pageSize: pagination.limit }}
        rowCount={rowCount}
        rows={paginatedFilecases?.data ?? []}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={(params) =>
          dispatchFilecaseDialog(params.row.id, { id: params.row.id })
        }
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
        onSortModelChange={([column]) => {
          refetch({
            ...variables,
            ...(column
              ? {
                  orderBy: column.field,
                  sortOrder: column.sort,
                }
              : {
                  orderBy: null,
                  sortOrder: null,
                }),
          });
        }}
      />
    </Stack>
  );
};

export default FilecasesTable;
